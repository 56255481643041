<template>
    <page :loading="loading">
        <!-- <div slot="head" class="flex-col">
        	<div class="flex-item flex-fill" style="text-align: center;">
        	    <el-input v-model="table.search.username" placeholder="请输入手机号" style="width:300px" clearable>
        	        <el-button slot="append" icon="el-icon-search" @click="LoadDatas()" />
        	    </el-input>
        	</div>
        </div> -->

        <page-table :datas="table.data" :page="table.search._page" :size="table.search._size" :total="table.search._count" @change="LoadDatas">
            <el-table-column prop="id"                             label="id" align="center" fixed="left"  width="120" />
            <el-table-column prop='user.username'                  label='用户名' align='center' />
			<el-table-column prop='phone_model'                    label='手机型号' align='center' />
            <el-table-column prop='created_at'                     label='登录时间' align='center' />
            <!-- <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="table.form = Object.assign({},scope.row),$refs['page-dialog'].open()" type="text" size="small">编辑</el-button>
                    <el-button @click="ActionDel(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column> -->
        </page-table>

        <page-dialog ref="page-dialog" :loading="loading">
            <el-form ref="form">
                <el-form-item label="用户id">
                    <el-input v-model="table.form.user_id" placeholder="请输入用户id" />
                </el-form-item>
                <el-form-item label="登录时间">
                    <el-date-picker v-model="table.form.login_time" placeholder="选择登录时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
                </el-form-item>
                <el-form-item label="退出登录时间">
                    <el-date-picker v-model="table.form.login_out_time" placeholder="选择退出应用时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
                </el-form-item>
				<el-form-item label="登录时长">
				    <el-input v-model="table.form.login_duration" />
				</el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="primary" @click="ActionSave(table.form)">保存</el-button>
            </div>
        </page-dialog>
    </page>
</template>
<script>
export default {
    data(){
        return {
            loading:false,
            table:{
                data:[],
                form:{},
                search:{
                    _page:1,
                    _size:20,
                    _count:0,
                }
            }
        }
    },
    mounted() {
        this.LoadDatas();
    },
    methods: {
        /**
         * 获取数据列表
         * @param page 分页-当前页
         * @param size 分页-每页数量
         * **/
        LoadDatas(page,size){
            page && (this.table.search._page = page),size && (this.table.search._size = size),this.loading = true;
            this.$get('app-user-login-record',this.table.search).then(res=>
            {
                this.table.data = res.data , this.table.search._count = res.extra.pageInfo.count , this.loading = false;
            }).catch(err=>
            {
                this.$notify.error({message: err || '网络请求错误'}),this.loading = false;
            });
        },

        /**
         * 保存
         * @param item 要保存的数据
         * **/
        ActionSave(item){
            this.loading = true;
            this.$save('app-user-login-record',item).then(res=>
            {
                this.loading = false , this.LoadDatas(),this.$notify({message: res.msg || '操作成功'}) , this.$refs['page-dialog'].close();
            }).catch(err=>
            {
                this.loading = false , this.$notify.error({message: err || '网络请求错误'});
            });
        },

        /**
         * 删除
         * @param item 要删除的数据
         * **/
        ActionDel(item){
            if( item.id ){
                this.loading = true;
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
                    this.$del('app-user-login-record/'+item.id).then(res=>{
                        this.loading = false , this.LoadDatas() , this.$notify({message: res.msg || '操作成功'});
                    }).catch(err=>{
                        this.loading = false , this.$notify.error({message:err||'删除失败'});
                    })
                }).catch(()=>{
                    this.loading = false;
                })
            }
        }
    },
}
</script>